@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Zen+Dots&display=swap');

@font-face {
    font-family: 'CroissantOne';
    src: url('./assets/fonts/CroissantOne-mjLV.otf') format('otf'),
        url('./assets/fonts/CroissantOne-dpgZ.ttf') format('truetype');
}


@layer base {
    body {
        /* font-family: 'CroissantOne'; */
        font-family: "DM Serif Text", serif;
        font-weight: 400;
        font-style: normal;
    }
}

/* width */
::-webkit-scrollbar {
    background: #0f172a;
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #383838;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #6b6b6b;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #666C84;
}